
import {
  IonContent,
  IonPage,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  onIonViewWillLeave,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/views/modal/AproModal.vue';
import { useManage } from '@/api/services/manage';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonList,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    const {
      LIMIT,
      next,
      items,
      fetchApplyDatas,
      isConnected,
      updateAproData
    } = useManage();
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users(); 
    const frDate = ref(setDateFormat(new Date(), 'YYYY-MM')); //ref('2023-01-01');
    const toDate = ref(setDateFormat(new Date(), 'YYYY-MM'));
    const isOpen = ref(false);

    async function listSearch() {
      await fetchApplyDatas();

    }
    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '사용자1차승인');
      await listSearch();
    });
    const form = reactive({
      modalState: false
    });
    async function onWillDismiss() {
      form.modalState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    async function itemClick(item: any) {
      console.log(item)
      form.modalState = true;
      const apro = item
      console.log(apro)
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'my-modal-class',
        componentProps: {
          apro: apro
        }, backdropDismiss: true
      });
      modal.present();
      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        updateAproData(item.userId,data)
      } else if (role == 'clear') {
        await listSearch();
      }else{
        await listSearch();
      }
      form.modalState = false;
    }
    onIonViewWillLeave(async () => {
      if (form.modalState == true) {
        await modalController.dismiss()
      }
    });

    return {
      LIMIT,
      next,
      items,
      frDate,
      toDate,
      calendar,
      fetch,
      listSearch,
      isOpen,
      fetchApplyDatas,
      setDateFormat,
      itemClick,
      isConnected,
      form,
      onWillDismiss,
      modalChange
    };
  }
});
