<template>
  <ion-page>
    <app-header back="user" title="사용자 1차승인" />
    <ion-item>
      <ion-grid>
        <ion-row class="ion-justify-content-start">
          <ion-col size='6'>아이디<br />사용자구분</ion-col>
          <ion-col size='3'>회사명<br />
            사업자번호
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-content>
      <div>
        <ion-list v-for="item in items" :key="item.userId" @click="itemClick(item)">
          <ion-item>
            <ion-grid>
              <ion-row class="ion-justify-content-start">
                <ion-col size='6'>{{ item.userId }}<br />{{ `${item.userPriv}(${item.gubn_nm})` }}</ion-col>
                <ion-col size='3'>{{ item.userName }}<br />
                  {{
                    item.userSano
                  }}
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import {
  IonContent,
  IonPage,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  onIonViewWillLeave,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/views/modal/AproModal.vue';
import { useManage } from '@/api/services/manage';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonList,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    const {
      LIMIT,
      next,
      items,
      fetchApplyDatas,
      isConnected,
      updateAproData
    } = useManage();
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users(); 
    const frDate = ref(setDateFormat(new Date(), 'YYYY-MM')); //ref('2023-01-01');
    const toDate = ref(setDateFormat(new Date(), 'YYYY-MM'));
    const isOpen = ref(false);

    async function listSearch() {
      await fetchApplyDatas();

    }
    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '사용자1차승인');
      await listSearch();
    });
    const form = reactive({
      modalState: false
    });
    async function onWillDismiss() {
      form.modalState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    async function itemClick(item: any) {
      console.log(item)
      form.modalState = true;
      const apro = item
      console.log(apro)
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'my-modal-class',
        componentProps: {
          apro: apro
        }, backdropDismiss: true
      });
      modal.present();
      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        updateAproData(item.userId,data)
      } else if (role == 'clear') {
        await listSearch();
      }else{
        await listSearch();
      }
      form.modalState = false;
    }
    onIonViewWillLeave(async () => {
      if (form.modalState == true) {
        await modalController.dismiss()
      }
    });

    return {
      LIMIT,
      next,
      items,
      frDate,
      toDate,
      calendar,
      fetch,
      listSearch,
      isOpen,
      fetchApplyDatas,
      setDateFormat,
      itemClick,
      isConnected,
      form,
      onWillDismiss,
      modalChange
    };
  }
});
</script>
<style scoped>
#container {
  margin: 0 auto;
  max-width: 800px;
  /* id="container" */
}

ion-label {
  margin: auto;
}

section {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

ion-icon {
  font-size: 20px;
}

ion-row {
  font-size: 0.7rem;
}

ion-col {
  padding: 0;
}

ion-grid {
  padding: 5px;
}

ion-list {
  padding: 0;
}

ion-chip {
  font-size: 0.8rem;
  margin: 0%;
}

.header-center {
  text-align: center;
}

ion-card {
  margin: 1px;
}

ion-input {
  text-align: center;
  color: black;
}

section {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

#signature-wrap {
  margin: 0.5em;
  padding: 1em;
}

.signature-pad {
  margin: 0 auto;
}

.signed {
  position: absolute;
  right: 15px;
}

.btn-wrap {
  margin: 0em;
}

.sign-tooltip {
  margin: 0.5em;
  color: #aaa;
}
</style>